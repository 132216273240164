import {
  mdiAccountGroupOutline, mdiAccountPlusOutline,
  mdiHomeOutline, mdiHospitalBuilding,
  mdiImageAlbum,
  mdiPercentOutline,
} from '@mdi/js'

export default [
  {
    subheader: 'menu.home1',
  },
  {
    title: 'menu.home',
    icon: mdiHomeOutline,
    color: 'info',
    to: 'home',
  },
  {
    subheader: 'menu.navigation',
  },

  {
    title: 'menu.company',
    icon: mdiHospitalBuilding,
    color: 'primary',
    to: 'company.index',
  },
  {
    title: 'menu.offer',
    icon: mdiPercentOutline,
    color: 'info',
    to: 'offer.index',
  },
  {
    title: 'menu.slider',
    icon: mdiImageAlbum,
    color: 'info',
    to: 'slider.index',
  },
  {
    title: 'menu.request',
    icon: mdiAccountPlusOutline,
    color: 'info',
    to: 'request.index',
  },
  {
    title: 'menu.users',
    icon: mdiAccountGroupOutline,
    color: 'error',
    children: [
      {
        title: 'menu.view_users',
        to: 'users.index',
        color: 'error',
      },
      {
        title: 'menu.roles',
        to: 'roles.index',
        color: 'error',
      },

      {
        title: 'menu.permissions',
        to: 'permissions.index',
        color: 'error',
      },
    ],
  },

  // {
  //   title: 'menu.site.settings',
  //   to: 'site.settings',
  //   color: 'error',
  //   class: 'spin',
  //   icon: mdiCog,
  // },

]
