import roleStore from '@/store/roles';
// eslint-disable-next-line import/no-cycle
import router from '@/router'

export default () => {
  const canAccess = to => {
    if (!to) return true;
    let { role, permission } = router.resolve({ name: to }).route.meta;
    if (permission && roleStore.state.permissions) {
      permission = permission.toLowerCase();

      return roleStore.state.permissions.findIndex(perm =>

      // console.log(`${perm.name.toLowerCase()} === ${permission}`, perm.name.toLowerCase() === permission);

        perm.name.toLowerCase() === permission) !== -1
    }
    if (role) {
      role = role.toLowerCase();

      return roleStore.state.role && roleStore.state.role.name.toLowerCase() === role
    }

    return true;
  }

  return {
    canAccess,
  }
}
