import Vue from 'vue'
import Vuex from 'vuex'
// eslint-disable-next-line import/no-cycle
import axios from '@axios';
// eslint-disable-next-line import/no-cycle
import router from '../router/index'
import roles from '@/store/roles'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loggedIn: localStorage.getItem('userData') !== null,
    user: JSON.parse(localStorage.getItem('userData')),
    token: localStorage.getItem('token'),
    loaded: false,
  },
  getters: {
    isAuthenticated(state) {
      return state.loggedIn
    },
    user(state) {
      return state.user
    },
  },
  actions: {
    async getUser(ctx) {
      try {
        const response = await axios.get('/auth/me')
        ctx.commit('SET_USER', response.data.data);
      } catch (e) {
        ctx.dispatch('logout');
      }
    },
    async logout(ctx) {
      // Redirect to login page
      ctx.state.loggedIn = false

      await router.push('/login')

      ctx.state.user = null
      ctx.state.token = null

      // Remove userData from localStorage
      // ? We just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem('token')

      // Remove userData & Ability from localStorage
      localStorage.removeItem('userData')

      // Remove roles  from localStorage
      localStorage.removeItem('roles')

      // Remove permissions from localStorage
      localStorage.removeItem('permissions')
    },
  },
  mutations: {
    SET_USER(ctx, user) {
      this.state.user = user
      this.state.roles = user.role
      this.state.loggedIn = true
      this.state.loaded = true;

      localStorage.setItem('userData', JSON.stringify(user))
      if (user.token) {
        this.state.token = user.token
        localStorage.setItem('token', user.token)
      }

      roles.commit('SET_ROLES_PERMISSIONS', user.role)

      // router.push('/')
    },
  },
  modules: {
  },
})
