const environment = {
  production: {
    APP_API_URL: 'https://se7a-whana.online/api',
    APP_PATH: '/',
  },
  development: {
    APP_API_URL: 'http://127.0.0.1:8000',
    APP_PATH: '/',
  },
};

module.exports = environment;
